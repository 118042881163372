<template>
  <div class="pools lottoPool">
      <div class="unlock">
        <div class="unlock__item" v-if="!isMetaMaskConnected">
          <div class="text-area" style="color:rgba(171, 196, 255, 0.9)">
            Connect wallet to check the results!</div>
        </div>
        <!-- <div v-if="isMetaMaskConnected && totalWinning" class="flex unlock__item q-pb-md"> -->
        <div class="row unlock__item q-pb-md text-center" style="color:rgba(171, 196, 255, 0.9)">
          <div class="col-sm-12 col-md-4 q-px-lg">
            <span class="label_lg">Total Remained : </span>
                {{myTotalWinning}} <span class="token_lg">STAR</span>
          </div>
          <div class="col-sm-12 col-md-4 q-px-lg">
              <span class="label_lg">Claimable : </span>
                  {{claimableWinning}} <span class="token_lg">STAR</span>
          </div>
          <div class="col-sm-12 col-md-4 q-px-lg">
              <span class="label_lg">Earned : </span>
                  {{winningMine}} <span class="token_lg">STAR</span>
          </div>
        </div>
        <div class="unlock__item q-pt-md">
          <div>
            <div>
              <button v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</button>
              <button v-if="isMetaMaskConnected && myTotalWinning" @click="claim">
                Claim Your <span>{{claimableWinning}}</span> STAR</button>
              <button v-if="isMetaMaskConnected && !myTotalWinning">NO WINNINGS</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
export default {
  computed: {
    isMetaMaskConnected() {
      return this.$store.state.account!=null;
    },
    myTotalWinning() {
      if(this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.myWinning.pendingRewards).isGreaterThan(0)) {
        return BigNumber(this.$store.state.lottery.myWinning.pendingRewards).shiftedBy(-18).toFormat(2);
      }
      return '0.00';
    },
    winningMine() {      
      if(BigNumber(this.$store.state.lottery.winningMine).isGreaterThan(0)) 
        return BigNumber(this.$store.state.lottery.winningMine).shiftedBy(-18).toFormat(2);
      return '0.00';
    },
    totalWinning() {
      let total = BigNumber(0);

      if (this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.myWinning.pendingRewards).isGreaterThan(0))
        total = BigNumber(this.$store.state.lottery.myWinning.pendingRewards);

      if(this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.winningMine).isGreaterThan(0))
        total = BigNumber(total).plus(BigNumber(this.$store.state.lottery.winningMine));

      if (BigNumber(total).isGreaterThan(BigNumber(0))) {
        return BigNumber(total).shiftedBy(-18).toFormat(2);
      }
      else
        return '0.00';
    },
    claimableWinning() {
      let total = BigNumber(0);

      if (this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.myWinning.pendingRewards).isGreaterThan(0))
        total = BigNumber(this.$store.state.lottery.myWinning.pendingRewards);
      else
        return '0.00';

      if(this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.winningMine).isGreaterThan(0))
        total = BigNumber(total).plus(BigNumber(this.$store.state.lottery.winningMine));

      if (BigNumber(total).isGreaterThan(BigNumber(0))) {
        if (BigNumber(total).isGreaterThan(this.$store.state.lottery.maxRewardPerClaim))
            return BigNumber(total).div(10).shiftedBy(-18).toFormat(2);
          else
            return BigNumber(total).shiftedBy(-18).toFormat(2);
      }
      else
        return '0.00';
    }
  },
  methods: {
    connectWallet() {
      this.$store.dispatch('connect');
    },
    claim() {
      this.$store.dispatch('claim');
    }
  }
}
</script>

<style scoped>
    .unlock{
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      /* background: #42a1e821; */
      border-radius: 10px;
    }
    .unlock__item{
      display: flex;
      justify-content: center;
      margin: 15px 0;
      font-size: 1rem;
    }

    .unlock__item button {
      background: conic-gradient(#b42626 83deg, #a50017 33deg, #9d3231 93deg, #9e2c2e 263deg, #a50017 113deg, #b42626 33deg);
      border-bottom: 0px solid #da3e52;
      box-shadow: 0px 0px 0px 0px #9e2c2e;
      color: #fff;
      text-shadow: 1px 1px 3px black;
      outline: none;
      padding: 15px 25px;
      border-top: 0px solid #da3e52;
      border-left: 0px solid #da3e52;
      border-right: 0px solid #da3e52;
      border-radius: 6px;
      border: 2px solid #da3e52;
      font-size: 1.1rem;
      text-transform: uppercase;
      cursor: pointer;
    }

    .unlock__item button span{
      font-weight: bold;
      color:yellow;
      text-shadow: 1px 1px 2px rgb(10, 32, 94);
      font-size: 1.5rem;
    }

    .unlock__item button:hover {
        transform: scale(1.01); 
    }

    .text-area{
      width: 100%;
      max-width: 200px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }

</style>