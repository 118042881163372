<template>
    <section id="workingArea">
        <unlock-wallet/>
        <finished-rounds/>
        <how-to-play/>
    </section>
</template>

<script>
    import UnlockWallet from '@/components/lottery/UnlockWallet.vue'
    import FinishedRounds from '@/components/lottery/FinishedRounds.vue'
    import HowToPlay from '@/components/lottery/HowToPlay.vue'

    export default {
        components: {
            UnlockWallet,
            FinishedRounds,
            HowToPlay
        },
        mounted() {
            this.$store.commit('read_lottery');
        }
    }
</script>

<style scoped>


</style>