<template>
  <div class="pools lottoPool">
      <div class="finish">
        <div class="finish__item">
          <div class="title text-white">StarBall Results</div>
        </div>
        <div class="finish__item">
          <div class="swapBtn_lg d-flex text-center align-items-center">
            <div :class="tab == 'your history' ? 'btn1':'btn'" @click="tab = 'your history'">Your History</div>
            <div :class="tab == 'all history' ? 'btn2':'btn'" @click="tab = 'all history'">All History</div>
          </div>
        </div>
        <div class="finish__item">
          <div class="card" v-if="tab == 'your history'">
            <div class="card__item card__item--header">
              <div style="font-size: 1.2rem; font-weight: bold;">
                Daily StarBall
                <span v-if="isMetaMaskConnected && resultIndex" style="padding: 5px 15px; border-radius: 20px; background-color: #99999950">{{resultIndex}}</span>
                <div v-if="isMetaMaskConnected && result" style="font-size: .8rem; color: #999; margin: 5px 0;">{{resultDate}}</div>
              </div>
              <div v-if="isMetaMaskConnected && resultIndex">
                <q-btn round dense flat class="q-mx-sm" icon="arrow_back" @click="goBack"/>
                <q-btn round dense flat class="q-mx-sm" icon="arrow_forward" @click="goNext"/>
                <q-btn round dense flat class="q-mx-sm" icon="keyboard_tab" @click="goEnd"/>
              </div>
            </div>
            <div class="card__item" style="padding: 70px;" v-if="!isMetaMaskConnected">
              <div style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                <div class="label" style="padding: 10px;">
                  Connect wallet to check your tickets</div>
                <div style="padding: 10px">
                  <button class="button" @click="connectWallet">Unlock Wallet</button>
                </div>
              </div>
            </div>
            <template v-if="isMetaMaskConnected && result">
              <div class="card__item" v-if="result.status==3">
                <div>
                  <div style="font-size: 1.2rem; font-weight: bold;">Winning Balls</div>
                </div>
                <div class="number">
                  <div class="number__item" style="background-color: #b434b9;">{{winningNumber[0]}}</div>
                  <div class="number__item" style="background-color: #6734b9;">{{winningNumber[1]}}</div>
                  <div class="number__item" style="background-color: #347db9;">{{winningNumber[2]}}</div>
                  <div class="number__item" style="background-color: #34b4b9;">{{winningNumber[3]}}</div>
                  <div class="number__item" style="background-color: #34b99c;">{{winningNumber[4]}}</div>
                  <div class="number__item" style="background-color: #b9a534;">{{winningNumber[5]}}</div>
                </div>
              </div>
              <div class="card__item" style="align-items: flex-start;">
                <div style="font-size: 1.2rem; font-weight: bold;">
                  <div>Winning Pool</div>
                    <div class="q-ml-md" style="font-size: 2rem; color: var(--q-color-info);">{{winningPool}} STAR</div>
                </div>
                <div class="match-items">
                  <div class="match-items__item">
                    <div class="title">Match all 6</div>
                    <div class="label">{{bracketSTAR[5]}} <span class="token">STAR</span></div>
                  </div>
                  <div class="match-items__item">
                    <div class="title">Match any 5</div>
                    <div class="label">{{bracketSTAR[4]}} <span class="token">STAR</span></div>
                  </div>
                  <div class="match-items__item">
                    <div class="title">Match any 4</div>
                    <div class="label">{{bracketSTAR[3]}} <span class="token">STAR</span></div>
                  </div>
                  <div class="match-items__item">
                    <div class="title">Match any 3</div>
                    <div class="label">{{bracketSTAR[2]}} <span class="token">STAR</span></div>
                  </div>
                </div>
              </div>
              <div v-if="myNumbers.length" class="card__item">
                <div style="font-size: 1.2rem; font-weight: bold;width:100%;">Your Balls
                </div>
                <div v-for="(myNumber,i) in myNumbers" :key="i" 
                  class="card__item" 
                  style="padding-bottom:0px; justify-content:right;width:100%;">
                  <div class="number my-number">
                    <div :class="['number__item','number'+isWinningNumber(myNumber,index)]" v-for="index in [0,1,2,3,4,5]" :key="index">{{getNumber(myNumber,index)}}</div>
                  </div>
                </div>
              </div>
              <div class="card__item" v-if="result.status==3">
                <div>
                  <div style="font-size: 1.2rem; font-weight: bold;">Your rewards: 
                    <span style="font-size: 1.4rem; color: var(--q-color-info);"> {{totalWinning}}</span>
                    <span class="q-ml-sm token">STAR</span>
                  </div>
                </div>                
              </div>
            </template>
            <div class="card__item" v-if="isMetaMaskConnected" style="justify-content: center;">
            </div>
          </div>
          <div class="card" v-if="tab == 'all history'">
            <div class="card__item card__item--header">
              <div style="font-size: 1.2rem; font-weight: bold;">
                Daily StarBall
                <span style="padding: 5px 15px; border-radius: 20px; background-color: #99999950">{{$store.state.lottery.lastAllResultId}}</span>
                <div style="font-size: .8rem; color: #999; margin: 5px 0;">{{resultDate}}</div>
              </div>
              <div>
                <q-btn round dense flat class="q-mx-sm" icon="arrow_back" @click="goBack"/>
                <q-btn round dense flat class="q-mx-sm" icon="arrow_forward" @click="goNext"/>
                <q-btn round dense flat class="q-mx-sm" icon="keyboard_tab" @click="goEnd"/>
              </div>
            </div>
            <template v-if="result">
              <div class="card__item item-label" v-if="result.status==3">
                <div>
                  <div style="font-size: 1.2rem; font-weight: bold;">Winning Balls</div>
                </div>
                <div class="number">
                  <div class="number__item number1">{{winningNumber[0]}}</div>
                  <div class="number__item number2">{{winningNumber[1]}}</div>
                  <div class="number__item number3">{{winningNumber[2]}}</div>
                  <div class="number__item number4">{{winningNumber[3]}}</div>
                  <div class="number__item number5">{{winningNumber[4]}}</div>
                  <div class="number__item number6">{{winningNumber[5]}}</div>
                </div>
              </div>
              <div class="card__item" style="align-items: flex-start;">
                  <div style="font-size: 1.2rem; font-weight: bold;">
                    <div>Winning Pool</div>
                    <div class="q-ml-md" style="font-size: 2rem; color: var(--q-color-info);">{{winningPool}} STAR</div>
                  </div>
                  <div class="match-items">
                    <div class="match-items__item">
                      <div class="title">Match all 6</div>
                      <div class="label">{{bracketSTAR[5]}} <span class="token">STAR</span></div>
                      <div class="sub-value">{{winner[5]}}</div>
                    </div>
                    <div class="match-items__item">
                      <div class="title">Match any 5</div>
                      <div class="label">{{bracketSTAR[4]}} <span class="token">STAR</span></div>
                      <div class="sub-value">{{winner[4]}}</div>
                    </div>
                    <div class="match-items__item">
                      <div class="title">Match any 4</div>
                      <div class="label">{{bracketSTAR[3]}} <span class="token">STAR</span></div>
                      <div class="sub-value">{{winner[3]}}</div>
                    </div>
                    <div class="match-items__item">
                      <div class="title">Match any 3</div>
                      <div class="label">{{bracketSTAR[2]}} <span class="token">STAR</span></div>
                      <div class="sub-value">{{winner[2]}}</div>
                    </div>
                  </div>
              </div>
              <div class="card__item">
                <div style="width: 200px;">
                  <div style="font-size: 1.2rem; font-weight: bold;">Total Players Today: <span> {{result.amountOfPurchasedPeople}}</span></div>
                </div>                
              </div>
            </template>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
export default {
  data() {
    return {
      tab: 'your history',
      cardExpand: false,
    }
  },
  computed: {
    isMetaMaskConnected() {
      return this.$store.state.account!=null;
    },
    resultAll() {
      return this.$store.state.lottery.lastAllResult;
    },
    resultIndex() {
      if(this.$store.state.lottery.myResultIds)
        return this.$store.state.lottery.myResultIds[this.$store.state.lottery.myResultIds.length-this.$store.state.lottery.myResultIndex-1];
      return '';
    },
    result() {
      if(this.tab=="all history")
        return this.$store.state.lottery.lastAllResult;
      else if(this.tab=="your history")
        return this.$store.state.lottery.lastMyResult;
      return null;
    },
    bracketSTAR() {
      let bracket = ['','','','','',''];
      if(this.result!=null) {
        for(let i = 2;i<6;i++) {
          bracket[i] = BigNumber(this.result.amountCollectedInSTAR)
            .times(this.result.rewardsBreakdown[i])
            .div(10000);

          bracket[i] = this.getDecimalData(bracket[i].shiftedBy(-18))
        }
      }
      return bracket;
    },
    winner() {
      let winner = ['','','','','',''];
      if(this.result!=null) {
        for(let i = 2;i<6;i++) {
          let w = parseInt(this.result.countWinnersPerBracket[i]);
          if(w==1)
            winner[i] = w + " ticket";
          else if(w>1)
            winner[i] = w + " tickets";
        }
      }
      return winner;
    },
    winningPool() {
      if(this.result!=null) {
        return this.getDecimalData(BigNumber(this.result.amountCollectedInSTAR).shiftedBy(-18));
      }
      return '-';
    },
    winningMine() {
      if(this.resultIndex==this.$store.state.lottery.currentLotteryId) {
        if(this.$store.state.lottery.myWinning!=null 
          && BigNumber(this.$store.state.lottery.myWinning.pendingRewards).isGreaterThan(0)) {
          return BigNumber(this.$store.state.lottery.myWinning.pendingRewards).shiftedBy(-18).toFormat(2);
        }
      }
      
      if(this.result!=null && this.$store.state.lottery.winningMine) 
        return BigNumber(this.$store.state.lottery.winningMine).shiftedBy(-18).toFormat(2);
      return 0;
    },
    winningNumber() {
      let result = this.result;
      let number = [0,0,0,0,0,0];
      if(result!=null) {
        number[0] = this.getNumber(result.finalNumber,0);
        number[1] = this.getNumber(result.finalNumber,1);
        number[2] = this.getNumber(result.finalNumber,2);
        number[3] = this.getNumber(result.finalNumber,3);
        number[4] = this.getNumber(result.finalNumber,4);
        number[5] = this.getNumber(result.finalNumber,5);
      }
      return number;
    },
    myNumbers() {
      if(this.$store.state.lottery.lastMyNumbers) {
        return this.$store.state.lottery.lastMyNumbers;
      }
      return [];
    },
    resultDate() {
      let date = new Date(this.result.endTime*1000);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      const dtf = new Intl.DateTimeFormat('en-US', options);
      return dtf.format(date);
    },
    totalWinning() {
      let total = BigNumber(0);

      if (this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.myWinning.pendingRewards).isGreaterThan(0))
        total = BigNumber(this.$store.state.lottery.myWinning.pendingRewards);

      if(this.$store.state.lottery.myWinning!=null 
        && BigNumber(this.$store.state.lottery.winningMine).isGreaterThan(0))
        total = BigNumber(total).plus(BigNumber(this.$store.state.lottery.winningMine));

      if (BigNumber(total).isGreaterThan(BigNumber(0))) {
        return BigNumber(total).shiftedBy(-18).toFormat(2);
      }
      else
        return '0.00'
    },
  },
  methods: {
    connectWallet() {
      this.$store.dispatch("connect");
    },
    goBack() {
      if(this.tab=='all history')
        this.$store.commit('read_history',{id:this.$store.state.lottery.lastAllResultId-1,tab:this.tab});
      else
        this.$store.commit('read_history',{id:this.$store.state.lottery.myResultIndex+1,tab:this.tab});
    },
    goNext() {
      if(this.tab=='all history')
        this.$store.commit('read_history',{id:this.$store.state.lottery.lastAllResultId+1,tab:this.tab});
      else
        this.$store.commit('read_history',{id:this.$store.state.lottery.myResultIndex-1,tab:this.tab});
    },
    goEnd() {
      if(this.tab=='all history')
        this.$store.commit('read_history',{id:this.$store.state.lottery.currentLotteryId,tab:this.tab});
      else
        this.$store.commit('read_history',{id:0,tab:this.tab});
    },
    getNumber(number,index) {
      return BigNumber(number).shiftedBy(-10+index*2).mod(100).mod(50).integerValue().toString();
    },
    isWinningNumber(number,index) {
      let num = this.getNumber(number,index);
      return this.winningNumber.indexOf(num)+1;
    },
    getDecimalData(value) {
        let limit_xxx = BigNumber(1000);
        let limit_xx = BigNumber(1);
        let limit_x = BigNumber(1).shiftedBy(-3);
        let limit_sm = BigNumber(1).shiftedBy(-6);
        let limit_md = BigNumber(1).shiftedBy(-9);
        let limit_lg = BigNumber(1).shiftedBy(-12);

        if (limit_lg.isGreaterThan(value)) {
            return value.toString();
        }
        else if (limit_md.isGreaterThan(value)) {
            return value.decimalPlaces(12, 1).toString();
        }
        else if (limit_sm.isGreaterThan(value)) {
            return value.decimalPlaces(9, 1).toString();
        }
        else if (limit_x.isGreaterThan(value)) {
            return value.decimalPlaces(6, 1).toString();
        }
        else if (limit_xx.isGreaterThan(value)) {
            return value.decimalPlaces(4, 1).toString();
        }
        else if (limit_xxx.isGreaterThan(value)) {
            return value.decimalPlaces(2, 1).toString();
        }
        else {
            return value.decimalPlaces(0, 1).toString();
        }
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 2rem;
  font-weight: bold;
}

.button{
  padding: 10px 25px;
  border: none;
  background-color: var(--q-color-info);
  color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px .5px black;
  cursor: pointer;
}
.finish{
  display: flex;
  flex-direction: column;
  /* padding: 100px 0; */
  /* background: #42a1e821; */
  border-radius: 10px;
  margin-bottom:20px;
}
.finish__item{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
}

.btn-group{
  background-color: var(--q-color-secondary);
  border-radius: 15px;
  /* padding: 3px; */
}
.btn-group .btn{
  width: 150px;
  /* margin: 5px; */
  border: none;
  padding: 5px;
  font-size: 1rem;
  border-radius: 15px !important;
  background: transparent;
  color: #fff;
  cursor: pointer;
}
.btn-group .btn--active{
  background: var(--q-color-info);
  color: var(--q-color-secondary);
}

.card__item{
  padding: 25px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.card__item--header{
  /* background: #f1f1f1; */
  background-color: #0000007a;
  border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
}
.card__item:first-child{
  border-radius: 10px 10px 0 0;
}
.card__item:last-child{
  border-radius: 0 0 10px 10px;
}

.card__item .item{
  display: flex;
  padding: 5px 0;
}
.card__item .item .item__title {
  font-size: 1.2rem;
  font-weight: bold;
  width: 150px;
}
.card__item .item .value {
  font-size: 2rem;
  font-weight: bold;
  color: var(--q-color-info);
}
.card__item .item .sub-value {
  color: rgb(166, 255, 0);
  font-weight: bold;
}
.card__item .item .buy-buttom {
  padding: 10px 25px;
  border: none;
  background-color: var(--q-color-info);
  color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px .5px grey;
  cursor: pointer;
}
.card__item .item .buy-buttom:hover {
  opacity: .8;
}

.card__item >>> button .row,
.card__item >>> button .col {
  padding:0 !important;
  margin:0 !important;
}

.number{
  display: flex;
}
.number__item{
  color: #f1f1f1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: rgba(153, 153, 153, 0.3);
}
.number1 {
  background-color: #b434b9;
}
.number2 {
  background-color: #6734b9;
}
.number3 {
  background-color: #347db9;
}
.number4 {  
  background-color: #34b4b9;
}
.number5 {
  background-color: #34b99c;
}
.number6 {
  background-color: #b9a534;
}
                
.card__expand__switcher{
  font-size: 1rem;
  font-weight: bold;
  color: var(--q-color-info);
  cursor: pointer;
}

.match-items{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.match-items__item{
  padding: 10px;
  margin: 5px;
}
.match-items__item .title{
  font-size: 1.3rem;
  color: var(--q-color-info);
}
.match-items__item .title--alt{
  font-size: 1rem;
  color: var(--q-color-accent);
}
.match-items__item .value{
  font-size: 1.2rem;
  font-weight: 500;
}
.match-items__item .sub-value{
  font-size: .9rem;
  color: rgb(166, 255, 0);
  font-weight: 500;
}

.match-items__item .label{
  font-size: 1.2rem;
  text-align: center;
}

@media (max-width: 650px) {
  .match-items__item .value{
    font-size: 1rem !important;
    font-weight: 500;
  }

  .match-items__item .value .token{
    font-size: 0.9rem !important;
    font-weight: 500;
  }

  .finish__item{
    margin: 0px !important;
  }

  .number__item{
    color: #f1f1f1;
    width: 32px;
    height: 32px;
    font-size: 1.1rem;
  }
}
</style>